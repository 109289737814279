<template>
  <footer ref="footer">
    <div class="container">
      <el-row>
        <el-col :xs="8" :sm="6" :md="6" :lg="3">
          <div class="footer-widget">
            <h3 class="footer-title" @click="$router.push('/')">首页</h3>
          </div>
        </el-col>
        <el-col :xs="8" :sm="6" :md="6" :lg="3">
          <div class="footer-widget">
            <h3 class="footer-title">产品中心</h3>
            <ul class="list-unstyled">
              <li @click="$router.push('/product/windowfilm')"><a>汽车窗膜</a></li>
              <li @click="$router.push('/product/price')"><a>产品价格</a></li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="8" :sm="6" :md="6" :lg="3">
          <div class="footer-widget">
            <h3 class="footer-title">服务中心</h3>
            <ul class="list-unstyled">
              <li @click="$router.push('/shops')"><a>专卖店查询</a></li>
              <li @click="$router.push('/warranty')"><a>质保查询</a></li>
              <li @click="$router.push('/order')"><a>订货系统</a></li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="8" :sm="6" :md="6" :lg="3">
          <div class="footer-widget">
            <h3 class="footer-title">关于FSK</h3>
            <ul class="list-unstyled">
              <li @click="$router.push('/brand')"><a>品牌介绍</a></li>
              <li @click="$router.push('/contact')"><a>联系我们</a></li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer-bottom">
      <a href="https://beian.miit.gov.cn/">鄂ICP备2024046767号</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'GlobalFooter'
}
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #102a1f;
  color: white;
  a {
    color: white;
    text-decoration: none;
  }
  .footer-title,
  li {
    cursor: pointer;
  }
  .footer-bottom {
    align-self: stretch;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111;
    color: white;
    line-height: 60px;
  }
}
.container {
  width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
  .list-unstyled {
    padding: 0;
    list-style: none;
  }
}
@media screen and (max-width: 1199px) {
  .container {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}
</style>
