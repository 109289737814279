import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '@/layouts/BaseLayout'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: BaseLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
        meta: { auth: false, title: '首页' }
      },
      {
        path: '/product/windowfilm',
        name: 'windowfilm',
        component: () => import(/* webpackChunkName: "home" */ '@/views/product/windowfilm'),
        meta: { auth: false, title: '汽车窗膜' }
      },
      {
        path: '/product/ppf',
        name: 'ppf',
        component: () => import(/* webpackChunkName: "home" */ '@/views/product/ppf'),
        meta: { auth: false, title: '漆面膜' }
      },
      {
        path: '/product/price',
        name: 'price',
        component: () => import(/* webpackChunkName: "home" */ '@/views/product/price'),
        meta: { auth: false, title: '产品价格' }
      },
      {
        path: '/shops',
        name: 'Shops',
        component: () => import(/* webpackChunkName: "home" */ '@/views/shops'),
        meta: { auth: false, title: '专卖店查询' }
      },
      {
        path: '/warranty',
        name: 'Warranty',
        component: () => import(/* webpackChunkName: "home" */ '@/views/warranty'),
        meta: { auth: false, title: '质保查询' }
      },
      {
        path: '/brand',
        name: 'Brand',
        component: () => import(/* webpackChunkName: "home" */ '@/views/brand.vue'),
        meta: { auth: false, title: '品牌介绍' }
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "home" */ '@/views/contact.vue'),
        meta: { auth: false, title: '联系我们' }
      },
      {
        path: '/card',
        name: 'card',
        component: () => import(/* webpackChunkName: "home" */ '@/views/card.vue'),
        meta: { auth: false, title: '质保书在线申请' }
      },
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "home" */ '@/views/login.vue'),
        meta: { auth: false, title: '登录' }
      },
      {
        path: '/order',
        name: 'order',
        component: () => import(/* webpackChunkName: "home" */ '@/views/order.vue'),
        meta: { auth: true, title: 'FSK订货管理' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
