<template>
  <header ref="header">
    <div class="container">
      <div class="logo" @click="$router.push('/')">
        <img src="../assets/logo.png" alt="logo" />
      </div>
      <el-menu :default-active="activeIndex" :router="true" class="hidden-sm-and-down" mode="horizontal">
        <el-menu-item index="/home">首页</el-menu-item>
        <el-submenu index="/product">
          <template slot="title">产品中心</template>
          <el-menu-item index="/product/windowfilm">汽车窗膜</el-menu-item>
          <!-- <el-menu-item index="/product/ppf">漆面保护膜</el-menu-item> -->
          <el-menu-item index="/product/price">产品价格</el-menu-item>
        </el-submenu>
        <el-submenu index="/service">
          <template slot="title">服务中心</template>
          <el-menu-item index="/shops">专卖店查询</el-menu-item>
          <el-menu-item index="/warranty">质保查询</el-menu-item>
        </el-submenu>
        <el-submenu index="/about">
          <template slot="title">关于FSK</template>
          <el-menu-item index="/brand">品牌介绍</el-menu-item>
          <el-menu-item index="/contact">联系我们</el-menu-item>
        </el-submenu>
      </el-menu>
      <div class="hidden-md-and-up md-menu">
        <el-popover placement="left-start" width="200" trigger="hover">
          <i slot="reference" class="menu-icon el-icon-s-operation"></i>
          <el-menu :default-active="activeIndex" :router="true" mode="vertical">
            <el-menu-item index="/home">首页</el-menu-item>
            <el-submenu index="/product">
              <template slot="title">产品中心</template>
              <el-menu-item index="/product/windowfilm">汽车窗膜</el-menu-item>
              <!-- <el-menu-item index="/product/ppf">漆面保护膜</el-menu-item> -->
              <el-menu-item index="/product/price">产品价格</el-menu-item>
            </el-submenu>
            <el-submenu index="/service">
              <template slot="title">服务中心</template>
              <el-menu-item index="/shops">专卖店查询</el-menu-item>
              <el-menu-item index="/warranty">质保查询</el-menu-item>
            </el-submenu>
            <el-submenu index="/about">
              <template slot="title">关于FSK</template>
              <el-menu-item index="/brand">品牌介绍</el-menu-item>
              <el-menu-item index="/contact">联系我们</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-popover>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '/home'
    }
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: center;
  background: url('~@/assets/bg_1.jpg') no-repeat top/cover;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    .logo {
      width: 82px;
      img {
        width: 100%;
      }
    }
    ::v-deep .el-menu {
      background-color: transparent;
      border-right: none;
      border: none;
      .el-menu-item {
        color: #fff;
        &:not(.is-disabled):hover {
          background-color: transparent;
          color: #fff;
          transform: scale(1.1);
        }
      }
      & > .el-menu-item.is-active {
        border-bottom: 2px solid #fff;
        font-weight: bold;
      }
      & > .el-menu-item:not(.is-disabled):focus {
        background-color: transparent;
        color: #fff;
      }
      .el-submenu {
        .el-submenu__title {
          &:hover {
            background-color: transparent;
            transform: scale(1.1);
          }
          color: #fff;
          i {
            color: #fff;
          }
        }
      }
      & > .el-submenu.is-active .el-submenu__title {
        border-bottom: 2px solid #fff;
        font-weight: bold;
      }
    }

    .menu-icon {
      font-size: 30px;
      color: #fff;
    }
  }
}

@media screen and (max-width: 1199px) {
  header {
    .container {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}
</style>
