import Vue from 'vue'
import VueQriously from 'vue-qriously'
import App from './App.vue'
import router from './router'
import store from './store'
import './core/lazy_use'
import './permission' // permission control

Vue.config.productionTip = false

Vue.prototype.$bus = new Vue()
Vue.use(VueQriously)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
