<template>
  <div class="container">
    <GlobalHeader ref="header" />
    <div class="main">
      <router-view />
    </div>
    <GlobalFooter ref="footer" />
  </div>
</template>

<script>
import GlobalHeader from '@/components/GlobalHeader.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
export default {
  components: {
    GlobalHeader,
    GlobalFooter
  }
}
</script>

<style lang="scss" scoped></style>
