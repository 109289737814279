import router from './router'
import storage from 'store'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'

const loginRoutePath = '/login'
const defaultRoutePath = '/order'

router.beforeEach((to, from, next) => {
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`)
  const token = storage.get('token')
  if (to.meta.auth && !token) {
    next('/login')
  }
  next()
})
